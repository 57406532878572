import { LuArrowUpRight } from "react-icons/lu";
import "./Bio.css";
// import resume from "./assets/KaiRO_Resume.pdf";
import picture from "./assets/me.jpeg";

const Bio = () => {
  const resume = "/KaiRO_Resume.pdf"; 
  return (
    <div className="bioPage">
      <div className="bio">
        <div class="homepagetxt">
          <p>
            I’m <i>Kai</i>, a junior software developer. I have a background in
            mental health psychology, with a concentration in computer science.
            This portfolio is an ongoing record of my projects and interests.
          </p>
        </div>
        <div class="homepageimg">
        {/* height="600" */}
          <img className="me" src={picture} alt="me"  />
        </div>
      </div>
      <div className="resume">
        <a href={resume} target="_blank" rel="noopener noreferrer">
          View Résumé
          <LuArrowUpRight className="arrow" />
        </a>
      </div>
    </div>
  );
};

export default Bio;
