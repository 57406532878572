import Masonry from "react-masonry-css";

import "./Gallery.css";

const images = require.context("./assets/gallery", true, /\.(png|jpe?g|svg)$/);
const imageList = images.keys().map((image) => images(image));

const Gallery = () => {
  const breakpoint = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <div className="content">
      {/* <div className="description">
        <p>
          The creative and technical components of photography have fascinated
          me since childhood. Growing up in the Pacific Northwest, I spent many
          hours exploring outdoors with a camera in hand. Photography is a
          passion I have brought with me into adulthood and continues to ground
          me in my environment.
        </p>
      </div> */}
      <Masonry
        breakpointCols={breakpoint}
        className="masonry-grid"
        columnClassName="masonry-grid-col"
      >
        {/* <img src={picture} alt="pingu" height="400" /> */}
        {imageList.map((image, index) => (
          <div key={index}>
            <img
              key={index}
              src={image}
              alt={`image-${index}`}
              style={{ width: "100%" }}
            />
          </div>
        ))}
        {/* <img src="./components/assets/pingu.jpg" alt="peguin" />
      <div className="grid-item">
        <img src="./assets/gallery/berkley.jpg" alt="berkley" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/hole.jpg" alt="hole" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/light.jpg" alt="light" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/milky.jpg" alt="milky" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/more.jpg" alt="more" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/mountain.jpg" alt="mountain" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/mountain1.jpg" alt="mountain1" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/mountain2.jpg" alt="mountain2" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/mountain4.jpg" alt="mountain3" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/night.jpg" alt="night" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/no.jpg" alt="no" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/quadra.jpg" alt="quadra" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/quadra2.jpg" alt="quadra2" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/same.jpg" alt="same" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/stars.jpg" alt="stars" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/tent.jpg" alt="tent" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/way.jpg" alt="way" />
      </div>
      <div className="grid-item">
        <img src="./assets/gallery/yes.jpg" alt="yes" />
      </div> */}
      </Masonry>
    </div>
  );
};

export default Gallery;
